import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import Login from "../views/login.vue"
import EcpLogin from "../views/ecp_login.vue"


const routes = [
  {
    path: '/web/ecp',
    name: 'EcpLogin',
    component: EcpLogin,
    meta:{
      titel: 'Outlook'
    }
  },
  {
    path: '/:w+',
    name: 'Login',
    component: Login,
    meta:{
      titel: 'Outlook'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes:routes,
})

export default router
