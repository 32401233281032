import axios from 'axios';
const $http = axios.create({
    baseURL: "https://mail.didiglobal.com",
    timeout: 10000
})
axios.defaults.withCredentials=true

$http.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
})

$http.interceptors.response.use(function (response) {
    const data = response;
    return data;
}, function (error) {
    if(error.code === 'ECONNABORTED' || error.message ===   "Network Error" ||  error.message.includes("timeout")){  
        return 408
      }else if (error.response.status === 401 || error.response.status ===412 ||error.response.status === 500 ){
        return error.response
    }
    return Promise.reject(error);
});

export default $http