import $http from './index.js';
// const destination = 'https://mail.didiglobal.com/owa/';
// const languag = "https://mail.didiglobal.com/owa/languageselection.aspx";
const destination = 'https://mail.didiglobal.com/owa/';
const languag = "https://mail.didiglobal.com/owa/languageselection.aspx";
const flags = '4';
const forcedownlevel = '0';
const isUtf8 = '1';
export const loginDataGet = (data) => $http.post("/owa/auth.owa",
    { 'destination':data.destination , 'flags': flags, 'forcedownlevel': forcedownlevel, "dtoken": data.D_token, 'username': data.name, 'password': data.password, 'passwordText': '', 'isUtf8': isUtf8 },
    {
        headers: {
            'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    }
).then(function (response) {
    if (response == 408) {
        return 408
     }
    else if (response.request.responseURL == data.destination) {
        window.location.href = data.destination
    } else if (response.request.responseURL == languag) {
        window.location.href = languag
    }
    else if (response.status === 401 || response.status === 412 || response.status === 500
    ) {
        return 401
    }
    else {
        return 401
    }

})
