<template>
  <router-view></router-view>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* .el-header { */
  /* background-color: #363D40; */
  /* 给头部设置一下弹性布局 */
  /* display: flex; */
  /* 让它贴标左右对齐 */
  /* justify-content: space-between; */
/* } */
.el-header {
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: white;
    background-color: #4a4c5b;
    padding: 0px 85px !important; 
}
</style>
